@use "../../assets/Mix.scss";

.dashboard_body {
  width: 100%;
  height: 100%;
  position: relative;
  padding-left: 5px;
  .scrollbar_theme{
    @include Mix.style-scrollbar;
  }

}
