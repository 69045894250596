@use "../../../assets/Mix.scss";
@use "../../../assets/Font.scss";
.asanBtc_field {
  margin: 0.25rem auto;
  div {
    display: flex;
    align-items: center;

    svg {
      color: var(--border-color);
    }
    span {
      @include Mix.lable_text;
    }
  }
  input {
    @include Font.Text300;
    @include Mix.border;
    width: 100%;
    display: block;
    @include Mix.filed-height;
    text-align: var(--site-side);
    color: var(--main-pen);
    &::placeholder {
      text-align: right;
    }

    &:focus-visible {
      outline: 0px solid white;
    }
    &::placeholder {
      @include Font.Text300;
    }
  }
  > input.error {
    border-color: var(--error);
  }
  > input.success {
    border-color: var(--success);
  }
}
