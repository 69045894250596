@use "../../assets/Mix.scss";

.action_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  svg {
    &:hover {
      cursor: pointer;
    }
  }
}

.table_skeleton_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  margin-top: 1rem;
  border-radius: var(--curve);
  div {
    width: 100%;
    height: 60px;
    background: var(--light-gray) !important;
    @include Mix.animate-skeleton;
    &:nth-child(even) {
      background: var(--light) !important;
    }
  }
}

.tableScroll {
  @include Mix.style-scrollbar;
}
