@use "../../assets/Font.scss";
@use "../../assets/Mix.scss";

.main_section {
  width: 100%;
  height: auto;
  h1 {
    margin-top: 0;
    @include Font.Title700;
    color: var(--main-pen);
  }

  .flex_container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 1rem;
    .square_item {
      width: 100%;
      height: 180px;
      @include Mix.white_box;
    }
  }
  .mt {
    margin-top: 1rem;
  }
  .rectangle {
    height: 270px !important;
  }
}
