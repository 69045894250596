@use "../../assets/Font.scss";
@use "../../assets/Mix.scss";

.profile_container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
  height: auto;
  width: fit-content;
  border-right: 1px solid var(--border-color);
  padding-right: 8px;
  padding-left: 1rem;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  .user_info {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    span:nth-child(1) {
      @include Font.Text300;
    }
    span:nth-child(2) {
      @include Font.Text200;
    }
  }
}

.skeleton_avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  @include Mix.animate-skeleton;
}

.skeleton_info > span {
  height: 12px;
  border-radius: var(--carve);
  width: 70px;
  @include Mix.animate-skeleton;


  &:nth-child(2) {
    margin-top: 10px;
    width: 25px;
  }
}


