@use "../../../assets/Mix";
@use "../../../assets/Font.scss";
.asanBtc_pass_field {
  margin: 0.25rem auto;
  div {
    display: flex;
    align-items: center;

    svg {
      color: var(--border-color);
    }
    span {
      @include Mix.lable-text;
    }
  }
  > div {
    position: relative;
    .password_requirements {
      background-color: white;
      display: block;
      width: 186px;
      height: auto;
      position: absolute;
      right: -200px;
      z-index: 99;
      border-radius: 8px;
      border: 1px solid var(--border-color);
      padding: 12px 8px;
      opacity: 0.9;
      h6 {
        padding: 0;
        margin-top: 0px;
        font-size: 10pt;
        font-weight: 100;
        font-family: "RobotMedium";
        display: flex;
        align-items: center;
        margin-bottom: 0px;

        span.Strong_pass {
          width: 10px;
          height: 10px;
          background-color: var(--success);
          border-radius: 50%;
          display: block;
        }
        span.Weak_pass {
          width: 10px;
          height: 10px;
          background-color: var(--error);
          border-radius: 50%;
          display: block;
        }
      }
      p {
        font-size: 10pt;
        margin: 4px 8px;
        color: var(--main-pen);
        line-height: 1.5;
      }
      ul {
        list-style: none;
        padding: 0px;
        margin: 8px 0;
        li {
          color: var(--main-pen);
          font-size: 9pt;
          display: flex;
          span {
            width: 5px;
            height: 5px;
            background-color: var(--border-color);
            border-radius: 50%;
            display: block;
          }
        }
      }
    }
    svg {
      top: 14px;
      left: 12px;
      position: absolute;
      cursor: pointer;
    }
    input {
      @include Font.Text300;
      @include Mix.border-card;
      width: 100%;
      @include Mix.filed-height;
      text-align: var(--site-side);
      color: var(--main-pen);

      &:focus-visible {
        outline: 0px solid var(--border-color);
      }
      &::placeholder {
        @include Font.Text300;
        text-align: var(--site-side);
      }
    }
    input.error {
      border-color: var(--error);
    }
    input.success {
      border-color: var(--success);
    }
  }
}
