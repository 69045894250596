@use "./Font.scss";

@mixin floor-padding {
  padding-left: 2rem;
  padding-right: 2rem;
  @media (max-width: 600px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media (min-width: 1400px) {
    padding-left: 4rem;
    padding-right: 4em;
  }
  @media (min-width: 1600px) {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
@mixin primary-btn {
  background-color: var(--primary);
  color: white;
  border-radius: var(--carve);
  @include Font.Title300;
  transition: all 0.5s ease-out;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
@mixin accent-btn {
  background-color: var(--accent);
  color: white;
  border-radius: var(--carve);
  @include Font.Title300;
  transition: var(--fast-transition);
  display: block;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  &:hover {
    background-color: var(--accent_opacity20);
  }
}
@mixin border {
  border-radius: var(--carve);
  border: 1px solid var(--border-color);
}
@mixin secondery-btn {
  padding: 1rem 1.25rem;
  color: var(--main-pen);
  border-radius: 12px;
  border: 1px solid var(--border-color);
  transition: var(--fast-transition);
  display: block;
  text-align: center;
  margin: 1rem auto;
  width: max-content;
  min-width: 100px;
  &:hover {
    border: 1px solid var(--light-gray);
    background-color: var(--light-gray);
    cursor: pointer;
    color: var(--dark-gray);
  }
}
@mixin border_card {
  @include border;
  padding: 0.5rem 1rem;
  margin: auto;
  display: flex;
  align-items: center;
}

@mixin lable_text {
  color: var(--dark-gray);
  margin: 0.5rem;
  @include Font.Title100;
}

@mixin white_box {
  background-color: white;
  // box-shadow: var(--box-shadow);
  padding: 16px;
  border-radius: var(--box-carve);
  border: 1px solid var(--border-color);
}

@mixin filed-height {
  padding: 1rem;
  height: 46px;
}

@mixin style-scrollbar {
  scroll-behavior: smooth;
  /* width */
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

@mixin animate-skeleton {
  background-color: var(--bg-color);
  position: relative;
  overflow: hidden;


  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(100%);
    animation: puls 3s infinite;
    background: linear-gradient(
      90deg,
      rgba(#f7f8f9, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#f7f8f9, 0)
    );
  }

  @keyframes puls {
    100% {
      transform: translateX(-100%);
      // right: 0;
    }
  }
}
