@use "../../assets/Font";

.login_box {
  width: 520px;
  background-color: white;
  box-shadow: var(--box-shadow);
  height: auto;
  min-height: 60vh;
  border-radius: 8px;
  padding: 1rem 2.5rem 3rem;
  .header {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    a {
      display: flex;
      align-items: center;
      color: var(--main-pen);
      @include Font.SubTitle400;
      &:hover {
        color: var(--dark-gray);
        cursor: pointer;
      }
    }
  }
  .logo {
    width: 110px;
    height: 110px;
    margin: 1.5rem auto;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-color);
    overflow: hidden;
    img {
      display: block;
      margin: auto;
      width: 70px;
      height: 70px;
    }
  }
  .login_fileds {
    width: 80%;
    margin: auto;
    .login_btn {
      margin-top: 3rem;
      .circularProgress {
        width: fit-content;
        margin: 0 auto;
      }
    }
  }
}
