@use "../../assets/Mix.scss";
@use "../../assets/Font.scss";

.container {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: var(--carve) !important;
  @include Mix.border;
  @include Mix.white_box;
}

.row_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    @include Font.Title400;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.row_tools {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}
