@use "../../assets/Mix.scss";
@use "../../assets/Font.scss";
.nav_menu {
  width: 100%;
  height: 100%;
  @include Mix.white_box;
  padding: 24px;
  overflow-y: scroll;
  @include Mix.style-scrollbar;

  .logo_holder {
    width: 40px;
    height: 40px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .menu_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    list-style: none;
    margin: auto;
    margin-top: 4rem;
    padding: 0;
  }
}

.item_menu {
  @include Font.SubTitle500;
  color: var(--main-pen);
  width: 100%;
  padding: 8px 10px;
  border-radius: var(--carve);
  transition: all 300ms linear;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 4px;
  svg {
    color: var(--main-pen);
  }
  &:hover {
    svg {
      color: var(--primary) !important;
    }
    cursor: pointer;
    color: var(--primary);
    background-color: var(--primary-light);
  }
}

.item_menu_active .item_menu {
  svg {
    color: var(--primary) !important;
  }
  // cursor: pointer;
  color: var(--primary);
  background-color: var(--primary-light);
}

.dyn_item {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;

  .arrow_child {
    flex: 1;
    text-align: end;
    padding-top: 4px;
    svg {
      transition: all 0.1s ease-in-out;
      transform-origin: center;
    }
  }

  .rotate {
    svg {
      rotate: 180deg;
    }
  }
}
