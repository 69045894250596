@font-face {
  font-family: "IranSans-Light";
  src: url("./assets/fonts/IRANSansWeb_Light.ttf");
  src: url("./assets/fonts/IRANSansWeb_Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "IranSans";
  src: url("./assets/fonts/IRANSansWeb.ttf");
  src: url("./assets/fonts/IRANSansWeb.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "IranSans-Medium";
  src: url("./assets/fonts/IRANSansWeb_Medium.ttf");
  src: url("./assets/fonts/IRANSansWeb_Medium.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "IranSans-SemiBold";
  src: url("./assets/fonts/IRANSansWeb_Bold.ttf");
  src: url("./assets/fonts/IRANSansWeb_Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
:root {
  --primary: #00a9ac;
  --primary-light: #effeff;
  --bg-color: #f7f8f9;
  --main-pen: #757575;
  --dark-gray: #585858;
  --dark-icon: #292D32;
  --border-color: #e2e2e2;
  --success: #12c258;
  --light: #f3f3f3;
  --error: #f21212bc;
  --light-gray: #cecece77;
  --light-pen: #eee;
  /* fonts */
  --semibold: "IranSans-SemiBold";
  --medium: "IranSans-Medium";
  --regular: "IranSans";
  --light-weight: "IranSans-Light";
  /* font family toast */
  --toastify-font-family: "IranSans !important";
  /* general */
  --box-shadow: 0px 4px 8px rgba(169, 169, 169, 0.11);
  --fast-transition: all 0.5s ease-out;
  --carve: 6px;
  --box-carve: 12px;
  --site-side: right;
}
body {
  margin: 0;
  direction: rtl;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
}
* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.Toastify__toast-body {
  font-family: "IranSans" !important;
}
